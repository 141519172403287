<template>
    <Layout>
      <template v-if="this.$route.name == 'add-card'">
        <PageHeader :title="title1" :items="items"></PageHeader>
      </template>
      <template v-else>
        <PageHeader :title="title2" :items="items"></PageHeader>
      </template>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body" id="medinext">
              <b-form enctype="multipart/form-data" ref="promotion">
                <div class="row">
                    <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                        <label for="card_title">Card Title <span style="color: red">*</span></label>
                        <b-form-input
                        type="text"
                        id="card_title"
                        placeholder="Enter Card Title"
                        v-model="form.title"
                        :class="{
                          'is-invalid': submitted && $v.form.title.$invalid,
                        }"
                        ></b-form-input>
                        <div v-if="submitted && !$v.form.title.required" class="invalid-feedback">
                            Campaign Title is required.
                        </div>
                    </b-form-group>
                    <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                        <!-- <label for="campaign_slug">Campaign Slug <span style="color: red">*</span></label>
                        <b-form-input
                        type="text"
                        id="campaign_slug"
                        placeholder="Enter Ad Title"
                        v-model="form.campaign_title"
                        :class="{
                          'is-invalid': submitted && $v.form.campaign_title.$invalid,
                        }"
                        ></b-form-input>
                        <div v-if="submitted && !$v.form.campaign_title.required" class="invalid-feedback">
                            Campaign Slug is required.
                        </div> -->
                        <label for="type">Campaign </label>
                            <multiselect
                                @search-change="fetchCampaign"
                                id="input-multi"
                                v-model="form.campaign_id"
                                :options="campaignDropdown"
                                :multiple="false"
                                track-by="key"
                                placeholder="Type here to search"
                                label="label"
                            >
                                <span slot="noOptions">Type here to search</span>
                            </multiselect>
                    </b-form-group>
                    <b-form-group id="input-group-2" class="col-lg-6 col-md-6 col-sm-12">
                        <label for="input-1">Preview HTML Content</label>
                        <b-form-textarea
                            id="input-9"
                            v-model="form.preview_html_content"
                            placeholder="Enter Preview HTML Content"
                            rows="12"
                        >
                        </b-form-textarea>
                    </b-form-group>
                    <b-form-group id="input-group-2" class="col-lg-6 col-md-6 col-sm-12">
                        <label for="input-1">Download HTML Content</label>
                        <b-form-textarea
                            id="input-9"
                            v-model="form.download_html_content"
                            placeholder="Enter Preview HTML Content"
                            rows="12"
                        >
                        </b-form-textarea>
                    </b-form-group>
                  <b-form-group class="col-lg-6 col-md-6 col-sm-12">
                    <label for="poster_image">Card Image</label>
                    <b-form-file
                      accept="image/*"
                      placeholder="Choose a file or drop it here..."
                      id="poster_image"
                      @change="readFile($event, 'poster_image')"
                      ref="poster_image"
                    >
                    </b-form-file>
                    <template
                      v-if="$route.name == 'edit-card' && edit.card_img_url"
                    >
                      <img
                        :src="edit.card_img_url"
                        width="128px"
                        height="128px"
                        style="object-fit: contain; margin-top: 5px"
                        v-if="this.form.type == 'png'"
                      />
                      <video
                              width="300px"
                              height="180px"
                              controls
                              v-if="this.form.type == 'gif'"
                            >
                              <source
                                :src="edit.card_img_url"
                                type="video/mp4"
                              />
                            </video>
                    </template>
                    <template v-if="card_img_url && this.form.type == 'png'">
                      <img
                        :src="card_img_url"
                        width="128px"
                        height="128px"
                        ref="card_img_url"
                        style="object-fit: contain; margin-top: 5px"
                        v-if="this.form.type == 'png'"
                      />
                    </template>
                    <template v-if="card_img_url && this.form.type == 'gif'">

                      <video
                              width="300px"
                              height="180px"
                              controls
                              v-if="this.form.type == 'gif'"
                            >
                              <source
                                :src="card_img_url"
                                type="video/mp4"
                              />
                            </video>
                    </template>
                  </b-form-group>
                  <b-form-group
                    label="Select an option"
                    label-for="static-select"
                  >
                    <b-form-select id="static-select" v-model="form.type">
                      <option value="" disabled>Select an type for card</option>
                      <option value="png">PNG</option>
                      <option value="gif">GIF</option>
                    </b-form-select>
                  </b-form-group>
                  <!-- <div> -->
                    <!-- <b-form-group label="Select a Speciality card:" label-for="speciality-dropdown" class="col-lg-6 col-md-6 col-sm-12">
                    <b-form-select
                        id="speciality-dropdown"
                        v-model="form.brand_speciality"
                        :options="dropdownSpeciality"
                        value-field="key"
                        text-field="label"
                        class="mb-3"
                        placeholder="Brand card set on speciality"
                    ></b-form-select>
                    </b-form-group> -->
                <!-- </div> -->
                </div>
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-2"
                  @click.prevent="submitData"
                >
                  <span v-if="this.$route.name == 'add-card'"
                    >Save Data</span
                  >
                  <span v-else>Update Data</span>
                </b-button>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  </template>
  
  <script>
  import Layout from "../../../layouts/main";
  import PageHeader from "@/components/page-header";
  import MixinRequest from "../../../../mixins/request";
  import mrappcampaignCardMixin from "../../../../mixins/ModuleJs/card";
//   import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
  
  export default {
    data() {
      return {
        submitted: false,
        title1: "Add Card",
        title2: "Edit Card",
        items: [
          {
            text: "List",
            href: "/field-rep-app/card",
          },
          {
            text: "Data",
          },
        ],
      };
    },
    mixins: [MixinRequest, mrappcampaignCardMixin],
    components: {
      Layout,
      PageHeader,
      Multiselect
    },
    validations: {
      form: {
        // title: { required },
        // slug: { required },
        // camp_icon: { required }
      },
    },
  };
  </script>
  